<template>
  <div>
    <a-descriptions
      title="运营商接口"
      :column="1"
      class="simiot-descriptions simiot-descriptions-max-9"
    >
      <a-descriptions-item label="内部API服务器域名">
        {{ apiAccount.api_server_domain }}
      </a-descriptions-item>

      <a-descriptions-item label="接口类型">
        {{ apiAccount.account_type }}
      </a-descriptions-item>

      <a-descriptions-item label="API访问路径">
        {{ apiAccount.api_url }}
      </a-descriptions-item>

      <a-descriptions-item label="APP ID" v-if="isCmp">
        {{ apiAccount.app_id }}
      </a-descriptions-item>

      <a-descriptions-item label="APP SECRET" v-if="isCmp">
        {{ apiAccount.app_secret }}
      </a-descriptions-item>

      <a-descriptions-item label="OPEN ID" v-if="isCmp">
        {{ apiAccount.open_id }}
      </a-descriptions-item>

      <a-descriptions-item label="接口用户名" v-if="isCop">
        {{ apiAccount.api_username }}
      </a-descriptions-item>

      <a-descriptions-item label="接口密码" v-if="isCop">
        {{ apiAccount.api_password }}
      </a-descriptions-item>

      <a-descriptions-item label="Tenant Id" v-if="isCop">
        {{ apiAccount.tenant_id }}
      </a-descriptions-item>

      <a-descriptions-item label="Encode Key" v-if="isCop">
        {{ apiAccount.encode_key }}
      </a-descriptions-item>

      <a-descriptions-item label="Iv Key" v-if="isCop">
        {{ apiAccount.iv_key }}
      </a-descriptions-item>

      <a-descriptions-item label="Push Key" v-if="isCop">
        {{ apiAccount.push_key }}
      </a-descriptions-item>

      <a-descriptions-item label="调用限频">
        {{ apiAccount.rate_display }}
      </a-descriptions-item>
    </a-descriptions>
  </div>
</template>

<script>
export default {
  name: 'ShowChinaUnicomApiAccount',
  props: {
    apiAccount: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isCmp: this.apiAccount.account_type === 'CMP',
      isCop: this.apiAccount.account_type === 'COP'
    }
  }
}
</script>

<style scoped>

</style>
